.collapsing__container {
  border-top: 1px solid var(--color-l-grey);
}

.collapsing__bar {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.collapsing__title {
  display: flex;
  justify-content: space-between;
  transition: all 0.25s ease-out;
  margin: 18px 0;
  font: var(--font-ml);
}

.collapsing__img {
  transition: transform 0.25s ease-out;
  margin: 10px 0 0;
  margin: auto 0;
  width: 14px;
  object-fit: contain;
}

.collapsing__img.open {
  transform: rotate(180deg);
}

.collapsing__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.collapsing__content.open {
  transition: max-height 0.5s ease-in;
}

.collapsing__text {
  display: flex;
  transition: all 1s ease-out;
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 16px;
  font: var(--font-xs);
  line-height: 1.6;
}

.collapsing__text.collapsing__text_dots {
  margin: 0;
  padding: 0;
}

.collapsing__bottomLine {
  margin: 0 0 0;
  border: none;
  border-bottom: 1px solid var(--color-l-grey);
}

.mainPage .collapsing__bottomLine {
  margin: 0;
}

.ourTeam__quoteText {
  transition: padding 0.5s ease-out;
}
